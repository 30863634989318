@import '../05-components/usp-bar';
@import '../05-components/icons-bar';
@import '../05-components/navlinks';
@import '../05-components/header-search';
@import '../experience/header/navigation-flyout-images';
@import '../experience/header/navigation-flyout-text';
@import '../experience/header/navigation-link-with-image';
@import '../experience/header/navigation-flyout-image-banner';

.main-header {
    top: 36px;
    position: absolute;
    background-color: $white;
    width: 100%;
    z-index: 100;
    -webkit-tap-highlight-color: transparent;

    /* Sticky header classes */
    .scroll-down & {
        transform: translate3d(0, -100%, 0);
        top: 0;
        position: fixed;
    }

    .scroll-up & {
        transform: none;
        top: 0;
        position: fixed;
    }

    .scroll-animate & {
        transition: all 0.3s ease;
    }

    @include mq ($tablet-max) {
        min-height: 60px;
    }
}
