@import '../../02-tools/mediaqueries';
@import '../../02-tools/mixins';
@import '../../01-settings/variables';

.dropmenu-item__images {
    display: flex;
    flex-wrap: wrap;
    margin-right: 20px;

    //scrollable image wrapper for tablet height
    @include mq($tablet-max) {
        height: calc(100vh - 320px);
        overflow-y: scroll;
        overflow-x: hidden;
    }
}

.image-tiles-content-wrapper {
    position: relative;
}

.image-tiles-holder {
    @include mq($tablet-max) {
        padding-bottom: $ratio-1-1;
    }
}

.image-tiles-link {
    @include absolute-full-width;

    display: block;
}

.image-tiles-title {
    font-size: 18px;
    text-transform: uppercase;
    margin-bottom: 2px;

    @include mq($mobile-max) {
        font-size: 16px;
    }
}

.image-tiles-subtitle {
    font-size: 14px;
    color: $grey4;

    @include mq($tablet-max) {
        color: $white;
    }

    @include mq($mobile-max) {
        font-size: 12px;
    }
}

.image-tiles-dropdown-description {
    width: 100%;

    @include mq($tablet-max) {
        position: absolute;
        color: $white;
        left: 20px;
        bottom: 24px;
    }

    @include mq($mobile-max) {
        position: absolute;
        left: 10px;
        bottom: 12px;
    }
}
