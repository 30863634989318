
@font-face {
    font-display: block;
    font-family: 'iconfont';
    src: url('../../fonts/icons.woff') format('woff');
}

@font-face {
    font-family: 'Skandinavisk';
    src: url('../../fonts/NoirSkandinavisk.woff2') format('woff2');
    font-weight: 400;
    font-display: block;
    font-style: normal;
}

@font-face {
    font-family: 'SkandinaviskBold';
    src: url('../../fonts/NoirSkandinavisk-Demibold.woff2') format('woff2');
    font-weight: 700;
    font-display: block;
    font-style: normal;
}
