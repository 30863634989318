/* stylelint-disable */
/*md
## modal
### Modal Example
```html_example

<button type="button" class="btn btn-primary" data-toggle="modal" data-target="#exampleModal" data-modal="openModal">
  Launch demo modal
</button>

<div class="modal js-modal display-none" id="exampleModal"
    tabindex="-1" role="dialog"
    aria-labelledby="exampleModal"
    aria-hidden="true"
    data-modal="closeModal"
    data-target="#exampleModal">
    <div class="modal__dialog" role="document">
        <div class="modal__content">
            <div class="modal__header">
                <h5 class="modal__title" id="exampleModal">Modal Title</h5>
                <button type="button" class="modal__close-icon iconfont-close"
                    data-dismiss="modal"
                    data-modal="closeModal"
                    data-target="#exampleModal"
                    aria-label="Close"></button>
            </div>
            <div class="modal__body">
                 ...
            </div>
        </div>
    </div>
</div>
```
*/
/* stylelint-enable */

.modal {
    @include fixed-full-width;

    z-index: 1050;
    overflow: hidden;
    outline: 0;
    background-color: rgba($black, 0.37);

    &.show {
        background-color: rgba($black, 0.37);
        display: block;
    }
}

.modal-open {
    overflow: hidden;
}

.modal__dialog {
    position: relative;
    width: calc(100% - 40px);
    pointer-events: none;
    max-width: 650px;
    margin: 25px auto;
    min-height: calc(100% - 56px);
    display: flex;
    align-items: center;

    // @include mq($mobile-small-all) {
    //     width: calc(100% - 10px);
    // }

    @include mq($not-mobile) {
        width: auto;
    }

    .modal--with-banner & {
        max-width: 600px;

        @include mq($desktop) {
            width: calc(100% - 40px);
            max-width: 1144px;
        }
    }

    .fade & {
        transform: translate(0, -25%);
    }

    .show & {
        transform: translate(0, 0);
    }
}

.modal__content {
    @include scrollbar;

    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 35px 20px 25px;
    pointer-events: auto;
    background-color: $white;
    outline: 0;
    max-height: calc(90vh - 50px);

    @include mq($not-mobile) {
        padding: 35px;
    }

    .modal--with-banner & {
        padding: 0;

        @include mq($desktop) {
            overflow: hidden;
        }
    }
}

.modal__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $grey2;
    padding-bottom: 25px;

    @include mq($not-mobile) {
        padding-bottom: 30px;
    }
}

.modal__title {
    @include h2;

    padding-right: 20px;

    &.pointer {
        cursor: pointer;
    }
}

.modal__close-icon {
    @include h3;
}

.modal__row {
    @include mq($desktop) {
        display: flex;
        justify-content: space-between;
        max-height: calc(90vh - 50px);
    }
}

.modal__col--image {
    @include mq($desktop) {
        width: calc(535 / 1144);
        max-height: 100%;
    }
}

.modal__col--content {
    @include scrollbar;

    padding: 30px 25px;

    @include mq($desktop) {
        padding: 65px;
        max-height: calc(90vh - 50px);
        width: calc(609 / 1144);
    }
}
