//icons bar for header
.site-nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    position: relative;
    padding: 18px 30px 6px;

    @include mq ($tablet-max) {
        padding: $padding-s 16px;
        min-height: 62px;
    }
}

.logo-home {
    @include mq ($tablet-max) {
        &.remove {
            display: none;
        }
    }
}

.header-logo__icon {

    @include mq($mobile-small) {
        width: 190px;
    }
}

.return-btn {
    display: none;
    font-size: 26px;
    color: $black;

    &::before {
        top: -8px;
    }

    @include mq($tablet-max) {
        &.is-open {
            display: block;
        }
    }
}

.navbar-menu {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
}

.navbar-menu-icon {
    display: block;

    &.cart {
        @include mq ($tablet-max) {
            &.remove {
                display: none;
            }
        }
    }

    &.search,
    &.user {
        @include mq ($tablet-max) {
            display: none;

            &.is-open {
                display: flex;
                position: absolute;
                left: 16px;
            }
        }
    }

    .search-field {
        width: 90%;
        margin-left: 15px;
        border: 0;
        border-bottom: 2px solid $grey2;
        height: 20px;
        margin-top: 5px;
        line-height: normal;
        transition: 0.25s ease-in-out;

        @include mq ($tablet-max) {
            width: 90%;
        }

        &:focus,
        &:hover {
            border-color: $black;
            outline: 0;
        }
    }
}

.total-cart-items {
    position: absolute;
    right: -7px;
    top: -5px;
    font-size: 12px;
}

//icon span elements
.header-icon {
    cursor: pointer;
    font-size: 22px;
    color: $black;

    &.iconfont-search {
        margin-right: 20px;

        @include mq($tablet-max) {
            display: none;
        }
    }

    &.iconfont-user {
        margin-left: 5px;
        margin-right: 5px;
    }

    &.iconfont-shopping-bag {
        margin-left: 22px;
        position: relative;

        @include mq ($tablet-max) {
            margin-left: 0;
            margin-top: -5px;
            display: inline-block;
        }
    }
}

/* navbar icons css */
.icon-wrapper {
    display: flex;
}

//hamburger button
.nav-toggler {
    border: 0;
    padding: 2px 2px 2px 10px;
    background-color: transparent;
    cursor: pointer;
    height: 22px;
    display: none;

    @include mq ($tablet-max) {
        display: block;
    }

    .bar {
        &,
        &::before,
        &::after {
            content: '';
            width: 18px;
            height: 2px;
            background-color: $black;
            display: block;
            transition: 0.3s;
        }

        &::before {
            transform: translateY(-5px);
        }

        &::after {
            transform: translateY(3px);
        }
    }

    &.is-open {
        .bar {
            background-color: transparent;

            &::before {
                transform: translateY(0) rotate(45deg);
            }

            &::after {
                transform: translateY(-2px) rotate(-45deg);
            }
        }
    }
}
