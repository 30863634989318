//First slide container
.navmenu-container {
    display: flex;
    justify-content: space-between;
    position: relative;
    max-width: 100%;
    height: 100%;
    padding: 6px 30px 0;
    background-color: $white;

    @include mq($not-mobile-tablet) {
        overflow-y: inherit;
    }

    @include mq($tablet-max) {
        flex-direction: column;
        justify-content: flex-start;
        position: absolute;
        touch-action: pan-y;
        overflow-y: auto;
        overflow-x: hidden;
        left: 0;
        right: 0;
        width: 100%;
        padding: $padding-s;
        border-top: 1px solid transparent;
        z-index: 15;
        display: none;

        &.is-open {
            border-top: 1px solid transparent;
            height: 100vh;
            display: block;
        }

        &.border-on-top {
            border-top: 1px solid $grey1;
        }

        &.stop-scrolling-y {
            overflow: hidden;
        }
    }
}

.navmenu-link-items {
    display: flex;
    margin-right: 16px;
    font-family: $font-primary-bold;

    &:nth-child(2) {
        margin-right: 0;
    }

    &:last-child {
        display: none;
    }

    @include mq($tablet-max) {
        flex-direction: column;
        margin-right: 0;

        &:not(:first-child) {
            font-family: $font-primary;
        }

        &:nth-child(2) {
            margin: 10px 0;
        }

        &:last-child {
            display: block;
            margin: 0 0 130px;
        }
    }
}

.navmenu-link-item {
    padding: 0 18px 18px;
    color: $grey4;
    border-bottom: 1px solid transparent;

    &:hover {
        color: $black;

        @include mq($not-mobile-tablet) {
            .dropmenu-links-container {
                display: block;
            }
        }
    }

    &.is-active {
        @include mq($tablet-max) {
            .dropmenu-links-container {
                transform: translateX(0);
            }

            &.navmenu-container {
                overflow-y: hidden;
            }
        }
    }

    &:first-child {
        padding-bottom: 0;
        padding-left: 0;
    }

    &:last-child {
        padding-right: 0;
    }

    .locale-selector__label,
    .locale-selector__select {
        font-size: 16px;
    }

    @include mq($tablet-max) {
        padding: 12px 0;

        &:first-child {
            padding: 12px 0;
        }
    }
}

.dropmenu-links-container {
    position: absolute;
    top: 100%;
    width: 100%;
    height: 100%;
    left: 0;
    background-color: $white;
    transition: $transition;
    touch-action: pan-y;

    @include mq($not-mobile-tablet) {
        display: none;
        border-top: 1px solid $grey2;
        min-height: 425px;
    }

    @include mq($tablet-max) {
        height: calc(100vh - 62px);
        top: 0;
        transform: translateX(100%);
        z-index: 25;
        overflow-x: hidden;
        overflow-y: auto;
        padding-bottom: $padding-m;
    }

    &:hover {
        display: block;
    }

    &:last-child {
        padding-top: 0;
    }

    &.about {
        @include mq($not-mobile-tablet) {
            .menu-items {
                justify-content: flex-end;
            }

            .dropmenu-item {
                padding-right: 45px;
            }
        }
    }
}

.dropmenu-item {
    padding: 16px 30px;
    font-family: $font-primary;
    font-size: 16px;

    &:last-child {
        padding-bottom: 20px;
    }

    @include mq($tablet-max) {
        padding: 12px 16px;
    }

    &.see-all-link {
        @include mq($not-mobile-tablet) {
            display: none;
        }
    }

    &.mobile-dropmenu-item {
        display: none;
        font-family: $font-primary-bold;

        @include mq($tablet-max) {
            display: block;
            border-bottom: 1px solid $grey1;
            border-top: 1px solid transparent;
        }
    }
}

.dropmenu {
    &.image-tiles-drop-menu {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .dropmenu-item {
            flex-basis: calc(33.3333333% - 32px);
            width: calc(33.3333333% - 32px);
            padding: 0;

            @include mq($tablet-max) {
                flex-basis: calc(50% - 8px);
                width: calc(50% - 8px);
            }
        }
    }
}

.header-link-item {
    @include mq($tablet-max) {
        display: block;

        &::after {
            @include iconfont-styles;
            @include iconfont-default;

            content: svg(chevron-right);
            font-size: 28px;
            color: $grey2;
            display: block;
            right: 10px;
            margin-top: -25px;
            position: absolute;
        }
    }
}

.menu-items {
    display: flex;
    justify-content: space-between;
    width: 100%;

    @include mq($tablet-max) {
        flex-direction: column;
        justify-content: flex-start;
    }

    &.image-tiles-menu {
        overflow: hidden;
    }
}

//Mini image in dropmenu
// TODO: what is this? If not implemented, please delete this
.mini-img-dropdown {
    display: none;

    @include mq($tablet-max) {
        display: block;
        width: 100%;

        @include image-aspect-ratio;
    }
}

/* Home & body image dropdown styling */
.nav-image-box {
    position: relative;
}
