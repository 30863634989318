.cookie-dialog-screen {
    @include absolute-full-width;

    height: 100%;
    z-index: 200;
}

.cookie-dialog-content {
    .cookie-screen-window & {
        background-color: $black;
    }

    .cookie-dialog-window & {
        background-color: $white;
        padding: 25px;
        margin: auto;
        max-width: 100%;
    }
}

.cookie-title {
    font-family: $font-primary-bold;

    .cookie-screen-window & {
        text-transform: uppercase;
        font-size: 20px;
        line-height: 22px;
        margin: 0 0 5px;
    }

    .cookie-dialog-window & {
        color: $black;
        letter-spacing: 1px;
        font-size: 18px;
        line-height: 24px;
        margin: 0 0 15px;
    }
}

.cookie-text {
    line-height: 20px;

    .cookie-screen-window & {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        align-items: center;
        font-size: 16px;
        margin: 0 0 5px;
        color: $white;
    }

    .cookie-dialog-window & {
        font-size: 15px;
        margin: 0 0 40px;
        color: $black;
    }
}

.cookie-option {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    font-size: 16px;
    line-height: 22px;
    align-items: flex-start;
    margin: 0 0 40px;
    border-bottom: 2px solid $grey1;
}

.cookie-screen-window {
    background-color: $black;
    position: fixed;
    transform: translate(-50%);
    left: 50%;
    bottom: 10%;
    width: 90%;
    z-index: 205;
    padding: 25px;

    .cookie-consent-accept {
        &:hover {
            background-color: $green;
            color: $white;
        }
    }
}

.cookie-text-description {
    @include mq($not-mobile) {
        width: 70%;
    }
}

.cookie-text-link-wrapper {
    @include mq($not-mobile) {
        width: 15%;
    }
}

.read-more {
    text-decoration: underline;
    text-transform: uppercase;
    font-size: 11px;
    font-family: $font-primary-bold;
    letter-spacing: 1.2px;
    line-height: 13px;
    display: block;
    margin: 12px 0;
}

/* Cookie modal */
.cookie-consent-dialog-content {
    position: fixed;
    left: 50%;
    top: 0;
    transform: translate(-50%, 20%);
    width: 50vw;
    z-index: 200;

    @include mq($tablet-max) {
        width: 70vw;
    }

    /* Switch for Dialogue window */
    .slider {
        @include absolute-full-width;

        cursor: pointer;
        background-color: $grey3;
        -webkit-transition: 0.4s;
        transition: 0.4s;

        &::before {
            position: absolute;
            content: "";
            height: 26px;
            width: 26px;
            left: 4px;
            bottom: 4px;
            background-color: $white;
            -webkit-transition: 0.4s;
            transition: 0.4s;
        }

        &.round {
            border-radius: 34px;

            &::before {
                border-radius: 50%;
            }
        }
    }

    .slide-checkbox {
        opacity: 0;
        width: 0;
        height: 0;

        &:checked + .slider {
            background-color: $green;
        }

        &:focus + .slider {
            box-shadow: 0 0 1px $green;
        }

        &:checked + .slider::before {
            transform: translateX(26px);
        }
    }

    .switch {
        position: relative;
        display: inline-block;
        width: 60px;
        height: 34px;
    }
}

.cookie-consent-button-wrapper {
    background-color: $grey1;
    padding: 25px;
}

.close-cookie-bnt {
    position: absolute;
    color: $white;
    font-size: 26px;
    right: -35px;
    top: -30px;
    width: 36px;
    height: 36px;
    border: 0;
}

.cookie-dialog-window {
    @include scrollbar;

    background-color: $white;
    width: 100%;
    z-index: 205;
    padding: 25px 0 0;
    max-height: 75vh;

    .read-policy-link {
        display: block;
        color: $grey3;
        margin-top: 10px;
        font-family: $font-primary-bold;

        &:hover {
            color: $black;
        }
    }

    .check {
        margin-right: 20px;
        position: relative;

        @include mq($mobile-max) {
            margin-top: 10px;
            margin-bottom: 25px;
        }
    }

    .label {
        cursor: pointer;

        @include mq($not-mobile) {
            width: 70%;
            padding-bottom: 50px;
        }
    }

    .label-title {
        font-size: 14px;
        font-family: $font-primary-bold;
        letter-spacing: 0.4px;
        line-height: 16px;
    }

    .label-text {
        font-size: 14px;
        letter-spacing: 0.4px;
        line-height: 18px;
    }
}
