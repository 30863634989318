[class*="iconfont-"] {
    &::before {
        @include iconfont-styles;
        @include iconfont-default;
    }
}

.iconfont-back-arrow::before {
    content: svg(back-arrow);
}

.iconfont-bag::before {
    content: svg(bag);
}

.iconfont-checkmark::before {
    content: svg(checkmark);
}

.iconfont-chevron-down::before {
    content: svg(chevron-down);
}

.iconfont-chevron-right::before {
    content: svg(chevron-right);
}

.iconfont-close::before {
    content: svg(close);
}

.iconfont-edit::before {
    content: svg(edit);
}

.iconfont-facebook-icon::before {
    content: svg(facebook-icon);
}

.iconfont-info::before {
    content: svg(info);
}

.iconfont-instagram-icon::before {
    content: svg(instagram-icon);
}

.iconfont-main-logo::before {
    content: svg(main-logo);
}

.iconfont-pinterest-icon::before {
    content: svg(pinterest-icon);
}

.iconfont-play::before {
    content: svg(play);
}

.iconfont-search::before {
    content: svg(search);
}

.iconfont-shopping-bag::before {
    content: svg(shopping-bag);
}

.iconfont-spotify-icon::before {
    content: svg(spotify-icon);
}

.iconfont-trash-outline::before {
    content: svg(trash-outline);
}

.iconfont-trash-s::before {
    content: svg(trash-s);
}

.iconfont-user::before {
    content: svg(user);
}

.iconfont-x-circle::before {
    content: svg(x-circle);
}

.iconfont-x::before {
    content: svg(x);
}

.iconfont-youtube-icon::before {
    content: svg(youtube-icon);
}


