@import '../../01-settings/variables';
@import '../../02-tools/mediaqueries';
@import '../../02-tools/mixins';

.image-container-wrapper {
    position: relative;
    width: 650px;
    max-width: 100%;

    .image-holder {
        padding-bottom: $ratio-16-9;
    }

    .dropdown-nav-image {
        @include image-aspect-ratio;
    }

    .nav-image-content {
        position: absolute;
        color: $white;
        width: 50%;
        top: 20px;
        left: 20px;
        font-size: 18px;
        text-align: left;

        @include mq($mobile-max) {
            font-size: 14px;
            top: 10px;
            left: 10px;
        }

        @include mq($mobile-pt) {
            font-size: 12px;
            top: 10px;
            left: 10px;
        }

        .image-content-title {
            margin: 0;
            font-family: $font-primary-bold;
        }

        .image-content-description {
            margin: 0;
            line-height: 17px;
        }
    }

    .image-button-wrapper {
        position: absolute;
        bottom: 20px;
        left: calc(50% - 90px);

        @include mq($tablet-max) {
            left: calc(50% - 76px);
            bottom: 10px;
        }

        .nav-img-btn {
            width: 180px;

            &.nav-img-btn--custom-text {
                color: var(--cta-text-color);
            }

            &.nav-img-btn--custom-bg {
                background-color: var(--cta-bg-color);

                &:hover {
                    background-color: transparent;
                    border: 1px solid var(--cta-bg-color);
                    color: var(--cta-bg-color);
                }
            }

            @include mq($mobile-max) {
                width: 152px;
            }
        }
    }
}
