//Footer links column
.footer-links-wrapper {
    display: flex;
    justify-content: space-between;
}

.footer-links-heading {
    text-transform: uppercase;
    font-size: 14px;
    line-height: 20px;
}

.footer-list {
    padding-top: 20px;
}

.footer-link-item {
    font-size: 14px;
    padding-bottom: 6px;

    &:hover {
        color: $grey4;
    }
}
