// ***************** LAYOUT ***************** //
// Description:
// Generic settings to start writing css
// --------------------------------------------------

// ==================================================
// Generic Layout Elements
// ==================================================

html {
    box-sizing: border-box;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased; /* stylelint-disable-line property-no-unknown */
    // Font variant
    font-variant-ligatures: none;
    height: 100%;
    // Smoothing
    text-rendering: optimizeLegibility;
}

*,
*::before,
*::after {
    box-sizing: inherit;
}

::selection {
    background: $black;
    color: $white;
    text-shadow: none;
}

body {
    font-family: $font-primary;
    height: 100%;
    -webkit-overflow-scrolling: touch;
    overflow-x: hidden;

    &.scroll-disabled {
        height: 100%;
        overflow: hidden;
        position: fixed;
        width: 100%;
    }
}

/* Page wrapper */
.main-page-wrapper {
    position: relative;
    margin: 0 auto;
    padding-top: 92px;
    max-width: 100%;
    min-height: calc(100vh - 253px);

    @include mq($tablet-max) {
        padding-top: 62px;
    }
}

/* Main container wrapper */
.main-container {
    margin: 0 auto;
    max-width: 1980px;
    width: 100%;

    &:not(.main-container--no-padding),
    &.main-container--padding-small {
        @include global-padding;
    }

    &.main-container--padding-medium {
        max-width: 1170px;
    }

    &.main-container--padding-big {
        max-width: 800px;
    }

    @include mq($tablet-max) {
        max-width: 100%;
        width: 100%;
    }
}

.translatez {
    transform: translateZ(0);
}

.visually-hidden,
.hide-text {
    @include hide-text;
}

.noselect {
    pointer-events: none;
    user-select: none;
}

.maxcolumn {
    @include global-padding;

    max-width: $max-width;
}

.main {
    @include mq($tablet-max) {
        padding-bottom: 50px;

        .pt_storefront &,
        .pt_customer-service &,
        .pt_product-search-result &,
        .pt_store-locator & {
            padding-bottom: 0;
        }
    }
}

/* sr styling */
.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
}

// ===================
// Hide and Show
// ===================
.display-none,
.d-none {
    display: none !important;
}

.display-small-mobile {
    display: none !important;

    @include mq($mobile-small-max) {
        display: block !important;
    }
}

.hide-small-mobile {
    display: none !important;

    @include mq($not-mobile-small) {
        display: block !important;
    }
}

.display-mobile-only {
    @include mq($not-mobile) {
        display: none !important;
    }
}

.display-large-mobile-only {
    @include mq($dt-tablet-ls) {
        display: none !important;
    }
}

.hide-mobile {
    @include mq($mobile-max) {
        display: none !important;
    }
}

.display-mobile-tablet,
.hide-desktop {
    @include mq($not-mobile-tablet) {
        display: none !important;
    }
}

.hide-mobile-tablet {
    @include mq($tablet-max) {
        display: none !important;
    }
}

.ie11-only {
    display: none;

    @include mq($ie11) {
        display: block;
    }
}

// Scroll prevent on all devices
.stop-scrolling {
    overflow: hidden;
}

// Transparent background
.backdrop-mask {
    &::before {
        @include fixed-full-width;

        content: "";
        background-color: rgba($black, 0.37);
        z-index: 200;
    }
}

// Scroll prevent
.stop-scrolling-on-touch {
    @include mq($tablet-max) {
        overflow: hidden;
        position: relative;
        touch-action: none;
    }
}

// Stickyfill polyfill
.sticky::before,
.sticky::after {
    content: '';
    display: table;
}

// Typography styling
p {
    @include p;
}

.h1,
h1 {
    @include h1;
}

.h2,
h2 {
    @include h2;
}

.h3,
h3 {
    @include h3;
}

.h4,
h4 {
    @include h4;
}

ul,
li,
nav ul,
nav ol {
    list-style: none;
    margin: 0;
    padding: 0;
}

.bold {
    font-family: $font-primary-bold;
}

.iconfont-outline {
    color: transparent;
    -webkit-text-stroke-color: $black;
    -webkit-text-stroke-width: 1px;
}

.iconfont-x-circle {
    &::before {
        color: transparent;
        -webkit-text-stroke-color: $error-red;
    }
}

.cursor-pointer {
    cursor: pointer;
}

.is-flex {
    display: flex;
}

.is-underline {
    text-decoration: underline;
}
