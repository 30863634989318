// _footer.scss

@import '../05-components/footer-icons';
@import '../05-components/footer-links';
@import '../05-components/footer-form';

.footer-content {
    background-color: $grey1;
    max-width: 100%;
}

.footer-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0 $padding-xl;

    @include mq($tablet-max) {
        padding: 0 $padding-l;
    }

    @include mq($mobile-max) {
        padding: 0 $padding-m;
    }
}

.footer-column {
    width: 25%;
    padding: $padding-m 0;

    @include mq ($tablet-max) {
        width: 50%;
        padding: $padding-m 0 $padding-s;
    }

    @include mq ($mobile-max) {
        width: 100%;

        &:first-child {
            order: 3;
        }

        &:last-child {
            order: -1;
        }
    }
}
