//usp bar

.main-usp-bar {
    background-color: $black;
    color: $white;
    font-size: 13px;
    line-height: 15px;
    min-height: 36px;
    padding: 0 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    @include mq($not-mobile-tablet) {
        padding: 0 30px;
    }
}
