// This file contains the styles for the global errors;
.error-messaging-plp {
    position: fixed;
    top: 70px;
    left: 50%;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    transform: translateX(-50%);
    z-index: 2000;
    text-align: center;
}

.alert {
    padding: 20px 30px;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 40px;
    border-radius: 2px;
}

.alert__text {
    margin: 0;
    color: $white;
}

.alert-danger {
    background-color: $red-dark;
    border: 1px solid $black2;
}

.alert-success {
    background-color: rgba($green1, 0.07);

    &::before {
        background-color: $green1;
    }

    .alert__title {
        color: $green2;
    }

    .alert__text {
        color: $green2;
    }

    .alert__product-removed {
        color: $black;
    }
}

.alert__btn {
    margin-left: 20px;
    line-height: 23px;
    height: 20px;
    color: $white;
}

.alert__btn-product {
    color: $black;
}
