.breadcrumb-container {
    border-top: 1px solid $grey2;
    padding: $padding-s $padding-m;
    margin-top: 3px;
    color: #525252;

    @include mq($not-desktop) {
        padding-left: $padding-s;
        padding-right: $padding-s;
    }

    .breadcrumb-list {
        margin-left: 0;
        padding-left: 0;
        font-size: 14px;
        line-height: 21px;
    }

    .breadcrumb-item {
        a {
            position: relative;

            @include mq($not-mobile) {
                margin-right: 40px;
            }

            @include mq($mobile-max) {
                display: none;
            }

            &::before,
            &::after {
                content: "";
                position: absolute;
                background-color: #525252;
                width: 1px;
                height: 9px;
                right: -20px;
            }

            &::before {
                transform: rotate(-45deg);
                top: 1px;
            }

            &::after {
                transform: rotate(45deg);
                top: 7px;
            }
        }

        &:nth-last-child(2) {
            a {
                @include mq($mobile-max) {
                    padding-left: 20px;
                    display: block;
                }

                &::before,
                &::after {
                    @include mq($mobile-max) {
                        left: 3px;
                    }
                }

                &::before {
                    @include mq($mobile-max) {
                        transform: rotate(45deg);
                        top: 3px;
                    }
                }

                &::after {
                    @include mq($mobile-max) {
                        transform: rotate(-45deg);
                        top: 10px;
                    }
                }
            }
        }

        &:last-child {
            a {
                @include mq($not-mobile) {
                    pointer-events: none;
                    font-weight: 600;
                }

                &::before,
                &::after {
                    display: none;
                }
            }
        }
    }
}
