@import '../../01-settings/variables';

.navigation-text {
    font-size: 16px;
    line-height: 24px;
    font-family: $font-primary;
}

.navigation-text__text {
    font-family: $font-primary;
    font-size: 16px;
    line-height: 24px;

    @include mq($tablet-max) {
        padding: 0 16px;
    }
}

.navigation-text__link {
    font-size: 18px;
    line-height: 24px;
}
