// _buttons.scss

button,
[type="button"],
[type="reset"],
[type="submit"] {
    border: 0;
    padding: 0;
    background: none;
    cursor: pointer;
    color: $black;

    &:focus {
        outline: 0;
        border-radius: 3px;
    }
}

.btn {
    font-size: 14px;
}

.btn--small {
    font-size: 12px;
}

.btn,
.btn--small {
    font-family: $font-primary-bold;
    min-height: 42px;
    padding: 10px 20px;
    line-height: 17px;
    text-align: center;
    text-transform: capitalize;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 1;
    overflow: hidden;
    text-decoration: none;

    &:hover {
        text-decoration: none;
    }

    &:focus {
        box-shadow: none;
    }

    &.btn--uppercase {
        text-transform: uppercase;
    }

    &.subscribe-btn {
        transition: all 0.2s ease-out;
        color: $white;
        border-color: $brown1;
        background-color: $brown1;

        &:hover,
        &:active {
            border-color: $white;
            background-color: $white;
            color: $black;
        }
    }

    &.btn-black,
    &.black {
        transition: all 0.2s ease-out;
        color: $white;
        border-color: $black;
        background-color: $black;

        &:hover,
        &:active {
            border-color: $black;
            background-color: $white;
            color: $black !important;
        }
    }

    &.btn-green,
    &.green {
        transition: all 0.2s ease-out;
        color: $white;
        border-color: $green;
        background-color: $green;

        &:hover,
        &:active {
            border-color: $green;
            background-color: $white;
            color: $green !important;
        }
    }

    &.btn-white,
    &.white {
        transition: all 0.2s ease-out;
        color: $black;
        border-color: $white;
        background-color: $white;

        &:hover,
        &:active {
            border-color: $black;
            background-color: $black;
            color: $white !important;
        }
    }

    &.btn-outline-black {
        border: 1px solid $black;
    }

    &.btn-outline-white {
        border: 1px solid $white;
    }

    &.btn-outline-green {
        border: 1px solid $green;
    }

    &.disabled,
    &:disabled,
    &[disabled="disabled"] {
        cursor: not-allowed;
        pointer-events: none;
        background-color: $grey2;
        border-color: $grey2;
        color: $grey4;
    }
}

.btn-block {
    width: 100%;
}

.btn-underline {
    text-decoration: underline;
}
