//search component
.search-wrapper {
    @include mq($not-mobile-tablet) {
        position: fixed;
        top: 36px;
        left: 0;
        width: 100%;
        height: 93px;
        background-color: $white;
        z-index: 300;
        transform: translateX(100%);
        transition: $transition;

        &.is-active {
            position: absolute;
            top: 0;
            transform: translateX(0);
        }

        &.remove-top {
            position: absolute;
            top: 0;
        }
    }

    .site-search {
        padding-top: 35px;
        margin-left: 30px;
        display: flex;

        @include mq($tablet-max) {
            padding-top: 0;
            margin-left: 0;
        }

        .iconfont-search {
            font-size: 22px;
        }
    }
}

//Reset search button
.reset-search-btn {
    border: 0;
    background-color: transparent;
    cursor: pointer;
    height: 22px;
    position: absolute;
    right: 30px;

    @include mq($tablet-max) {
        display: none;
    }

    .bar {
        background-color: transparent;

        &::before,
        &::after {
            content: '';
            width: 18px;
            height: 2px;
            display: block;
            transition: 0.3s;
            background-color: $black;
        }

        &::before {
            transform: translateY(2px) rotate(45deg);
        }

        &::after {
            transform: translateY(0) rotate(-45deg);
        }
    }
}
