.form__row {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;

    @include mq($mobile-max) {
        margin: 0 -5px;
    }
}

.form__group {
    position: relative;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-basis: 100%;
    padding: 0 10px;
    margin-bottom: 20px;

    @include mq($mobile-max) {
        padding: 0 5px;
    }

    &.half-not-mobile {
        @include mq($not-mobile) {
            flex-basis: 50%;
        }
    }

    &.half-not-mobile-tablet {
        @include mq($not-mobile-tablet) {
            flex-basis: 50%;
        }
    }

    &.horizontal-align {
        flex-direction: row;
        align-items: flex-start;
        flex-wrap: wrap;
        line-height: 1;
    }
}

.form__label {
    display: flex;
    padding: 0;
    font-size: 14px;
    line-height: 19px;
    color: $black;
    word-wrap: break-word;
    position: absolute;
    top: 15px;
    left: 30px;
    pointer-events: none;
    transition: all 0.4s ease-in-out;

    &.form__label--checkbox {
        display: inline;
        position: relative;
        top: unset;
        left: unset;
    }

    &.form__label--footer {
        pointer-events: auto;
    }

    &.form__label--small {
        font-size: 10px;
        line-height: 10px;
    }

    &.form__label--select {
        top: -12px;
        background-color: $white;
        padding: 3px;
        font-size: 10px;
        color: $grey4;
        left: 17px;
    }
}

.invalid-feedback {
    margin-top: 2px;
}

.form__control {
    display: block;
    border: 1px solid $grey3;
    background-color: $white;
    color: $black;
    font-size: 16px;
    font-weight: normal;
    font-family: inherit;
    padding: 15px 20px;
    height: 48px;
    width: 100%;

    &:focus,
    &:active {
        outline: none;
    }

    &.footer-form__input {
        margin-bottom: 0;
        background-color: transparent;
        color: rgba($black, 0.75);
        font-weight: normal;
        font-family: inherit;
        padding: 8px;
        height: 37px;
    }

    &.form__control--outline-black {
        border-color: $black;
    }

    &._has_error {
        &,
        &:focus,
        &::placeholder {
            color: $error-red;
            outline: $error-red;
            border-color: $error-red;
        }
    }

    &.is-invalid {
        ~.invalid-feedback {
            color: $error-red;
            font-size: 10px;
            line-height: 12px;
        }

        ~.form__label {
            &::before {
                content: '!';
                display: inline-block;
                color: $error-red;
                border: 2px solid $error-red;
                width: 18px;
                height: 18px;
                border-radius: 100%;
                text-align: center;
                vertical-align: text-bottom;
                font-size: 12px;
                line-height: 13px;
            }
        }
    }

    &::placeholder {
        color: transparent;

        ._form-content & {
            color: inherit;
        }
    }

    &:focus,
    &:not(:placeholder-shown) {
        ~.form__label {
            top: -12px;
            background-color: $white;
            padding: 3px;
            font-size: 10px;
            color: $grey4;
            left: 17px;

            &::before {
                content: '';
                border: 0;
                width: 0;
                height: 0;
            }
        }
    }

    &.required,
    &:required {
        ~.form__label {
            &::after {
                content: '*';
                display: inline-block;
            }
        }
    }
}

// AC forms
._error-inner {
    color: $error-red;
    font-size: 10px;
    margin: 5px 0;
}

input[type=checkbox] {
    margin-right: 2px;
    display: inline-block;
    vertical-align: middle;
}

select {
    color: $black;
}

.form__password-info,
.form__terms {
    margin: 0;
    line-height: 24px;
}

.form__terms {
    font-size: 12px;
    color: $grey4;
}

.form__terms-link {
    font-family: $font-primary-bold;
    text-decoration: underline;
}

//Custom checkbox
.form__checkbox {
    position: absolute;
    opacity: 0;

    + label {
        position: relative;
        cursor: pointer;
        padding: 0;
    }

    // Box.
    + label::before {
        content: '';
        margin-right: 10px;
        display: inline-block;
        vertical-align: text-top;
        width: 20px;
        height: 20px;
        background: $white;
        border: 1px solid $grey3;
        border-radius: 3px;
    }

    // Box focus
    &:focus + label::before {
        box-shadow: 0 0 0 3px $black (0, 0, 0, 0.12);
    }

    // Box checked
    &:checked + label::before {
        background: $black;
        border: 1px solid $black;
    }

    // Disabled state label.
    &:disabled + label {
        color: $grey3;
        cursor: auto;
    }

    // Disabled box.
    &:disabled + label::before {
        box-shadow: none;
        background: $grey3;
    }

    // Checkmark could be replaced with an image
    &:checked + label::after {
        content: '';
        position: absolute;
        left: 5px;
        top: 9px;
        background: $white;
        width: 2px;
        height: 2px;
        box-shadow:
            2px 0 0 $white,
            4px 0 0 $white,
            4px -2px 0 $white,
            4px -4px 0 $white,
            4px -6px 0 $white,
            4px -8px 0 $white;
        transform: rotate(45deg);
    }
}

.form__select {
    width: 100%;
    height: 48px;
    border: 1px solid $grey2;
    padding: 0 15px;
    border-radius: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: url(../../img/svg/chevron-down.svg);
    background-position: right 20px center;
    background-repeat: no-repeat;
    background-size: 20px 20px;
}

// Checkbox Error Newsletter form
._checkbox-radio .invalid-feedback {
    color: #f00000;
    font-size: 10px;
    line-height: 12px;
}

//Footer Newsletter form
.newsletter-form-footer {
    .form__label {
        font-size: 10px;
        line-height: 10px;
    }
}
