.message-container {
    padding: 20px 30px;
    margin-bottom: 40px;
    border-radius: 2px;

    p {
        margin: 0;
        font-size: 14px;
        line-height: 17px;
    }

    @include mq($mobile-max) {
        min-width: 80%;
    }
}

.message-container--grey {
    background-color: $grey1;
    border: 1px solid $grey2;
}

.message-container--green {
    background-color: $green1;
    border: 1px solid $black2;
    color: $white;
}

.message-container--red {
    background-color: $red-dark;
    border: 1px solid $black2;
    color: $white;
}

.message-container--alert {
    position: fixed;
    top: 30px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2000;
    text-align: center;
}
