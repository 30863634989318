//footer icons
.footer-logo-wrapper {
    display: block;
    padding-bottom: $padding-m;
}

.footer-logo-img {
    width: 160px;
    height: 80px;
}

.footer-socials-wrapper {
    margin-left: -5px;
}

.footer-icon-link {
    margin-left: 25px;
    animation-duration: 0.5s;
    animation-fill-mode: both;
    backface-visibility: hidden;

    &:first-child {
        margin-left: 0;
    }

    .icon {
        font-size: 24px;

        &:hover {
            &::before {
                animation-name: pulse;
                animation-duration: 0.5s;
                animation-fill-mode: both;
                backface-visibility: hidden;
            }
        }
    }
}

//Animation for icons hover

@keyframes pulse {
    from {
        transform: scale3d(1, 1, 1);
    }

    50% {
        transform: scale3d(1.4, 1.4, 1.4);
    }

    to {
        transform: scale3d(1, 1, 1);
    }
}
