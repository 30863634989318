@import '../../02-tools/mediaqueries';

.list-image-wrapper {
    @include mq($not-mobile-tablet) {
        max-width: 500px;
        padding-left: 30px;
    }

    @include mq($tablet-max) {
        max-height: calc(100vh - 70px);
        overflow-x: hidden;
        overflow-y: auto;
        padding-bottom: 15px;
        touch-action: pan-y;
    }
}

.navigation-flyout-wrapper {
    @include mq($tablet-max) {
        padding: 0 16px 120px;
    }
}


/* Fragrance menu styling */
.dropitem-image-wrapper {
    display: flex;
    flex-wrap: wrap;
    margin-right: 20px;

    //scrollable image wrapper for tablet height
    @include mq($tablet-max) {
        height: calc(100vh - 320px);
        overflow-y: scroll;
        overflow-x: hidden;
    }

    .image-tiles-item-wrapper {
        flex-grow: 1;
        width: 33.33%;
        position: relative;

        @include mq($tablet-max) {
            width: calc(50% - 8px);
            justify-content: space-between;
            margin: 16px 16px 0 0;

            .image-tiles-content-wrapper {
                position: relative;

                .image-tiles-holder {
                    padding-bottom: $ratio-1-1;
                }
            }
        }

        @include mq($mobile-medium) {
            margin: 4px 0;
            width: 100%;
        }

        .image-tiles-dropdown-description {
            width: 100%;

            @include mq($tablet-max) {
                position: absolute;
                color: $white;
                left: 20px;
                bottom: 24px;
            }

            @include mq($mobile-max) {
                position: absolute;
                left: 10px;
                bottom: 12px;
            }
        }
    }
}
