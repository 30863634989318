.locale-selector {
    display: flex;

    .footer-row--locale-selector & {
        justify-content: space-between;
        margin-bottom: 54px;
        border-bottom: 1px solid $black;
    }

    .navmenu-link-item & {
        @include arrow('down', 'after', 12px, 2.5px);

        position: relative;

        &::after {
            position: absolute;
            right: 7px;
            top: 7px;
            color: $grey2;
        }
    }
}

.locale-selector__wrapper {
    display: inline-block;
    position: relative;

    .cart__locale-selector & {
        @include arrow('down', 'after', 9px, 2.5px);

        &::before {
            content: "";
            display: inline-block;
            height: 1px;
            width: 100%;
            background-color: #000;
            position: absolute;
            bottom: 0;
            right: 0;
        }

        &::after {
            position: absolute;
            right: -15px;
            top: 7px;
            color: $grey2;
        }
    }
}

.locale-selector__label,
.locale-selector__select {
    font-size: 14px;
    line-height: 20px;
}

.locale-selector__select {
    appearance: none;
    background-color: transparent;
    border: none;
    height: auto;
    padding: 0;
    margin: 0;
    width: auto;
    margin-left: 5px;
    font-family: $font-primary-bold;

    &:focus {
        outline: 0;
    }

    .footer-row--locale-selector & {
        width: 140px;
        margin-left: 0;
        font-family: $font-primary;
        margin-bottom: 5px;
        text-align: right;
    }

    .navmenu-link-item & {
        flex-grow: 1;
        text-align: left;
        z-index: 1;
    }

    .cart__locale-selector & {
        position: relative;
    }
}

.locale-selector__option {
    font-family: inherit;
}

.locale-selector__label {
    .footer-row--locale-selector & {
        text-transform: uppercase;
        margin-bottom: 5px;

        &::after {
            content: ':';
            display: inline-block;
            vertical-align: text-bottom;
        }
    }
}
