// sass devision for mixin background-ratio

@use 'sass:math';

// ***************** MIXINS ***************** //
// Description:
// Global mixins used in the project
// --------------------------------------------

// icon font styles:

@mixin iconfont-styles {
    color: inherit;
    font-family: $iconfont;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    text-decoration: none;
    text-transform: none;
    line-height: 1;
}

@mixin iconfont-default {
    color: inherit;
    display: inline-block;
    font-size: inherit;
    position: relative;
    vertical-align: middle;
}

// example:
// .your-class {
//     &::before {
//         @include iconfont-styles;
//         @include iconfont-default;
//         content: svg(iconname);
//         display: block;
//         font-size: 40px;
//         color: $white;
//     }
// }

// image backgrounds:

@mixin bg-cover {
    background-color: $grey3;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

// example:
// img {
//     @include bg-cover;
// }

@mixin background-ratio($height, $width) {
    padding-top: math.div($height, $width) * 100%;
}

// example:
// .img {
//     &::after {
//         @include background-ratio(280px, 300px);
//     }
// }

/// Mixin to customize scrollbars
/// Beware, this does not work in all browsers
/// @author Hugo Giraudel
/// @param {direction} $direction - Scrollbar direction (vertical/horizontal)
/// @param {Length} $size - Horizontal scrollbar's height and scrollbar's width
/// @param {Color} $foreground-color - Scrollbar's color
/// @param {Color} $background-color [mix($foreground-color, white, 50%)] - Scrollbar's color
/// @example scss - Scrollbar styling
///   @include scrollbars(.5em, slategray);
// Source: https://css-tricks.com/snippets/sass/custom-scrollbars-mixin/

@mixin scrollbar($direction: vertical, $size: 5px, $foreground-color: $black, $background-color: $white) {
    // For Google Chrome

    @if ($direction == vertical) {
        overflow-y: auto;
    } @else {
        overflow-x: auto;
    }

    &::-webkit-scrollbar {
        width: $size;
        height: $size;
    }

    &::-webkit-scrollbar-thumb {
        background: $foreground-color;

        @if ($direction == vertical) {
            border-left: 1px solid $background-color;
            border-right: 1px solid $background-color;
        } @else {
            border-top: 1px solid $background-color;
            border-bottom: 1px solid $background-color;
        }
    }

    &::-webkit-scrollbar-track {
        background: $background-color;
    }

    scrollbar-color: $foreground-color $background-color;
    scrollbar-width: thin;
}

// example:
// @include scrollbar;
// @include scrollbar($grey1, $grey2, 0);
// @include scrollbar($grey1, $grey2, 10px);

@mixin hide-text() {
    border: 0 !important;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden !important;
    padding: 0;
    position: absolute !important;
    width: 1px;
}

@mixin visibility-hidden {
    display: block;
    visibility: hidden;
    overflow: hidden;
    height: 0;
    width: 0;
    opacity: 0;
}

// ==================================================
// Global Padding
// ==================================================

@mixin global-padding {
    padding-left: $padding-m;
    padding-right: $padding-m;

    @include mq($tablet-max) {
        padding-left: $padding-s;
        padding-right: $padding-s;
    }
}

@mixin global-negative-margin {
    margin-left: -$padding-xl;
    margin-right: -$padding-xl;

    @include mq($dt-min) {
        margin-left: -$padding-l;
        margin-right: -$padding-l;
    }

    @include mq($tablet-max) {
        margin-left: -$padding-s;
        margin-right: -$padding-s;
    }
}

@mixin image-contrast-overlay($opacity: 0.15, $color: $white) {
    background-color: $color;
    bottom: 0;
    content: '';
    left: 0;
    opacity: $opacity;
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1;
}

@mixin image-aspect-ratio {
    display: block;
    width: 100%;
    height: 100%;
    max-width: none;
    max-height: none;
    object-fit: cover;
    object-position: 50% 50%;
    position: absolute;
    top: 0;
    left: 0;
}

@mixin absolute-full-width {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

@mixin fixed-full-width {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

@mixin absolute-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

@mixin fixed-button-bottom {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 30px 16px;
    background-color: $white;
    z-index: 3;
    box-shadow: 0 -10px 12px rgba($black, 0.05);
}

//custom arrows
// EXAMPLE: @include arrow('down', 'after', 12px, 2.5px);

@mixin arrow($type: 'right', $el: 'after', $size: 8px, $weight: 1.5px) {
    &::#{$el} {
        content: "";
        display: inline-block;
        width: $size;
        height: $size;
        border-top: $weight solid;
        border-left: $weight solid;
        vertical-align: middle;

        @if $type == 'right' {
            transform: translate(50%, -50%) rotate(135deg);
        }@else if $type == 'down' {
            transform: translate(50%, -50%) rotate(225deg);
        }@else if $type == 'left' {
            transform: translate(50%, -50%) rotate(-45deg);
        }
    }
}

// Custom plus sign

@mixin plus($size: 18px, $left: -5px) {
    content: '\002B';
    color: $black;
    font-size: $size;
    display: inline-block;
    position: absolute;
    left: $left;
    align-self: center;
}

@mixin overflow-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

// Typography //

@mixin p {
    font-size: 14px;
    line-height: 19px;
}

@mixin h1 {
    margin: 0;
    font-size: 36px;
    line-height: 40px;
    font-weight: normal;

    @include mq($tablet-max) {
        font-size: 22px;
        line-height: 30px;
    }
}

@mixin h2 {
    margin: 0;
    font-size: 30px;
    line-height: 35px;
    font-weight: normal;

    @include mq($tablet-max) {
        font-size: 18px;
        line-height: 25px;
    }
}

@mixin h3 {
    margin: 0;
    font-size: 24px;
    line-height: 20px;
    font-weight: normal;

    @include mq($tablet-max) {
        font-size: 18px;
        line-height: 15px;
    }
}

@mixin h4 {
    margin: 0;
    font-size: 18px;
    line-height: 15px;
    font-weight: normal;
}
