/* stylelint-disable */
/*md

## Spinner

### Spinner Example

```html_example
<div class="veil" style="min-height: 200px;">
    <div class="underlay"></div>
    <div class="spinner">
        <span class="dot1"></span>
        <span class="dot2"></span>
    </div>
</div>
```

*/
/* stylelint-enable */

.image-preloader,
.veil {
    @include absolute-full-width;

    z-index: 100;
}

$spinner-size: 32px;

.image-preloader__underlay,
.spinner {
    @include absolute-full-width;

    text-align: center;
}

.image-preloader__underlay {
    background-color: transparent;
}

.spinner {
    background-color: transparentize(transparent, 0.65);
    transition: background-color 0.3s ease-in-out;
}

.loading + .image-preloader .image-preloader__spinner {
    &::before {
        border-color: transparent;
    }

    &::after {
        background-color: transparent;
    }
}

.dot1 {
    &::before {
        border-color: transparent;
    }

    &::after {
        background-color: transparent;
    }
}

.loading + .image-preloader .image-preloader__spinner,
.dot1 {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    height: $spinner-size;
    width: $spinner-size;

    &::before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        margin: auto;
        height: 32px;
        width: 32px;
        border-width: 3px;
        border-style: solid;
        border-bottom-color: transparent;
        border-radius: 50%;
        animation: sk-rotate 1.5s cubic-bezier(0.77, 0, 0.175, 1) infinite;
    }

    &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        margin: auto;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        animation: sk-rotate-after 1.5s cubic-bezier(0.77, 0, 0.175, 1) infinite;
    }
}

@keyframes sk-rotate {
    0% {
        transform: rotate(0deg);
    }

    40% {
        transform: rotate(180deg);
    }

    60% {
        transform: rotate(180deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes sk-rotate-after {
    0% {
        transform: translate3d(0, -32px, 0) scale(0, 2);
        opacity: 0;
    }

    50% {
        transform: translate3d(0, 0, 0) scale(1.25, 1.25);
        opacity: 1;
    }

    100% {
        transform: translate3d(0, 8px, 0) scale(0, 0);
        opacity: 0;
    }
}
