
.footer-form-wrapper {
    width: 100%;
    padding: 0 ;
    font-family: $font-primary;

    a {
        text-decoration: revert;
    }
}

.footer-form__input {
    &::placeholder {
        font-size: 12px;
    }
}

.footer-form__title {
    font-family: $font-primary;
    text-transform: uppercase;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 10px;
}

.footer-form__group {
    width: 65%;
    flex-basis: 65%;

    label {
        display: none;
    }
}

.footer-form__btn-wrap {
    flex-grow: 1;
}

.footer-form__btn {
    min-height: 37px;
    padding: 0;
    font-family: $font-primary;
    font-size: 11px;
    letter-spacing: 1px;

    &:hover {
        border: 1px solid $black;
        border-left: 0;
    }
}

.footer-form__row {
    margin-bottom: 15px;
    display: flex;
}

.footer-form__confirmation {
    display: none; // hidden by default
}

.footer-form-wrapper {
    &.was-submitted {

        .footer-form__confirmation {
            display: flex;
            flex-flow: column;
        }

        .newsletter-form-footer {
            display: none;
        }
    }
}
